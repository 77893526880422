import { Component } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  template: '<div>401: You have no rights to access this. Please Login</div>',
})
export class UnauthorizedComponent {
  public message: string;
  public values: any[] = [];

  constructor() {
    this.message = 'UnauthorizedComponent constructor';
  }
}
