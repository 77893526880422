import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarcodeScannerComponent } from './barcode-scanner.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [BarcodeScannerComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    SharedModule,
  ],
  exports: [BarcodeScannerComponent]
})
export class BarcodeScannerModule { }
