<div class="row">
    <div class="col d-flex justify-content-center align-items-center" style="margin-right: 0px;">
        <img src="assets/icons/document-icon.svg" style="width: 75px;margin-top: 110px;" />
    </div>
</div>
<div class="row" style="margin-bottom: 80px;">
    <div class="col d-flex flex-column justify-content-center align-items-center" style="margin-right: 0px;">
        <span style="font-size: 24px;font-weight: 500;color: #777777;" [translate]="'no-transaction.paragraph-1'"
            [translateParams]="{default: 'No Transactions'}"></span>
        <ng-container *ngIf="!isSearchResult">
            <span style="font-size: 20px;max-width: 285px;text-align: center;color: #777777;" [translate]="'no-transaction.paragraph-2'"
                [translateParams]="{default: 'As soon as you use your account Transactions will appear here.'}"></span>
            <span style="font-size: 16px;margin-bottom: 110px;font-weight: 500;color: #777777;" [translate]="'no-transaction.paragraph-3'"
                [translateParams]="{default: 'Check back later'}"></span>
        </ng-container>
    </div>
</div>