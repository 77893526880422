import { Component, EventEmitter, OnInit, OnDestroy, Output, Input } from '@angular/core';
import { BrowserQRCodeReader, BrowserCodeReader, IScannerControls } from '@zxing/browser';
import { ChecksumException, FormatException, NotFoundException } from '@zxing/library';
import { environment } from 'src/environments/environment';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

const NO_VIDEO_ERROR_MSG: string = _('barcode-scanner.no-video-device-message');
const WAITING_FOR_SCAN_MSG: string = _('barcode-scanner.waiting-for-scan-message');

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {
  @Output() qrCodeScanned = new EventEmitter<string>();
  @Input() autoScan: boolean;

  scannerVideoWidthSm = environment.scannerVideoSize.sm.width;
  scannerVideoHeightSm = environment.scannerVideoSize.sm.height;
  scannerVideoWidthMd = environment.scannerVideoSize.md.width;
  scannerVideoHeightMd = environment.scannerVideoSize.md.height;

  codeReader: BrowserQRCodeReader;
  decodingStyle = 'continuously';
  devices: MediaDeviceInfo[] = [];
  selectedDeviceId: string;
  scannerControl: IScannerControls;

  isScanning: boolean;
  qrCodeFound: boolean;
  scannedString: string;

  videoWidth: number = this.scannerVideoWidthMd;
  videoHeight: number = this.scannerVideoHeightMd;

  constructor() { }

  ngOnInit(): void {
    this.codeReader = new BrowserQRCodeReader();
    BrowserCodeReader.listVideoInputDevices()
      .then((videoInputDevices) => {
        this.devices = videoInputDevices;
        if (videoInputDevices.length >= 1) {
          this.selectedDeviceId = videoInputDevices[0].deviceId;
        }

        window.dispatchEvent(new Event('resize'));  // to automatically adjust barcode scanner size if opened in mobile browser

        // handle no device!
        if (videoInputDevices.length === 0) {
          this.scannedString = NO_VIDEO_ERROR_MSG;
          return;
        }

        if (this.autoScan) {
          this.start();
        }
      })
      .catch((err) => {
        console.error(err);
      });

    this.reset();
  }

  ngOnDestroy(): void {
    this.stop();
  }

  start(): void {
    if (!this.decodingStyle) { return; }

    if (this.decodingStyle === 'once') {
      this.decodeOnce(this.codeReader, this.selectedDeviceId);
    } else {
      this.decodeContinuously(this.codeReader, this.selectedDeviceId);
    }

    this.qrCodeFound = false;
    this.isScanning = true;
  }

  stop(): void {
    if (this.scannerControl) {
      this.scannerControl.stop();
      this.scannerControl = null;
    }

    this.isScanning = false;
    this.qrCodeFound = false;
  }

  onDeviceChange($event) {
    this.stop();
    this.start();
  }

  reset(): void {
    this.scannedString = WAITING_FOR_SCAN_MSG;
    this.qrCodeFound = false;
  }

  decodeOnce(codeReader: BrowserQRCodeReader, selectedDeviceId: string) {
    codeReader.decodeOnceFromVideoDevice(selectedDeviceId, 'video').then(result => {
      if (result) {
        this.scannedString = result.getText();
      }
    }).catch((err) => {
      console.error(err);
      this.scannedString = err;
    });
  }

  decodeContinuously(codeReader: BrowserQRCodeReader, selectedDeviceId: string) {
    const controls = codeReader.decodeFromVideoDevice(selectedDeviceId, 'video', (result, err) => {
      if (result) {
        // properly decoded qr code
        this.scannedString = result.getText();
        this.qrCodeFound = true;
        this.qrCodeScanned.emit(result.getText());
      }

      if (err) {
        // As long as this error belongs into one of the following categories
        // the code reader is going to continue as excepted. Any other error
        // will stop the decoding loop.
        //
        // Excepted Exceptions:
        //
        //  - NotFoundException
        //  - ChecksumException
        //  - FormatException

        if (err instanceof NotFoundException) {
        }

        if (err instanceof ChecksumException) {
        }

        if (err instanceof FormatException) {
        }
      }
    });

    controls.then(c => this.scannerControl = c);
  }

  setVideoSizeMd() {
    this.videoWidth = this.scannerVideoWidthMd;
    this.videoHeight = this.scannerVideoHeightMd;
  }

  setVideoSizeSm() {
    this.videoWidth = this.scannerVideoWidthSm;
    this.videoHeight = this.scannerVideoHeightSm;
  }
}
