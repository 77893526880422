import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from './shared/services/app-settings/app-settings.service';
import { AuthService, AuthState } from './auth/auth.service';
import { AUTH_ATTEMPT, LOGOUT_MARKER, PATH_AUTOLOGIN, REDIRECT, UNAUTHORIZED } from './shared/constants/value';
import { LocalStorageService } from './shared/services/local-storage/local-storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'eCredits CashierApp';
  version: string = environment.version;

  public isAuthenticated$: Observable<boolean> = this.authService.isLoggedIn().pipe(
    map((isAuthenticated: boolean) => isAuthenticated),
  );

  constructor(
    private authService: AuthService,
    private router: Router,
    private renderer: Renderer2,
    private localStorageService: LocalStorageService,
    private swUpdate: SwUpdate,
    private platformLocation: PlatformLocation) {
  }

  ngOnInit(): void {
   if(this.platformLocation.href.includes('resetsession=1')) {
      this.router.navigate([PATH_AUTOLOGIN], {queryParams: {resetsession: 0}});
    } else {
      this.checkAuth();
    }
  }

  private checkAuth() {
    this.authService.checkAuth()
      .subscribe((authState) => {
        const { pathname, href, search } = window.location;

        switch (authState) {
          case AuthState.NotAuthenticated: {
            this.localStorageService.remove(LOGOUT_MARKER);

            if (pathname !== PATH_AUTOLOGIN) {
              let authAttempt: number | undefined = this.localStorageService.read(AUTH_ATTEMPT);
              this.localStorageService.write(AUTH_ATTEMPT, !authAttempt ? 1 : authAttempt++);

              if (!authAttempt) {
                this.localStorageService.write(REDIRECT, pathname + search);
              }

              this.authService.authorize(null);
            }

            break;
          }
          case AuthState.IncompleteRegistration: {
            window.location.href = AppSettingsService.authServiceUrl + '/Account/Confirm?ReturnUrl=' + href;
            break;
          }
          default: {
            if (this.localStorageService.read(LOGOUT_MARKER)) {
              this.authService.logoff();
              break;
            }

            this.navigateToStoredEndpoint();
            break;
          }
        }
      });

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_READY') {
          alert('New version available.');
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        }
      });
    }
  }

  private navigateToStoredEndpoint() {
    const path = this.localStorageService.read(REDIRECT);
    const currentUrl = this.router.url;

    if (currentUrl === path) {
      return;
    }

    if (!path || path.toString().includes(UNAUTHORIZED)) {
      this.router.navigate(['/']);
    } else if (!this.localStorageService.read(AUTH_ATTEMPT)) {
      this.localStorageService.remove(AUTH_ATTEMPT);
      return;
    } else {
      this.router.navigate([path]);
    }

    this.localStorageService.remove(AUTH_ATTEMPT);
  }
}
