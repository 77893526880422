import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-transaction',
  templateUrl: './no-transaction.component.html',
})
export class NoTransactionComponent implements OnInit {
  @Input() isSearchResult: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
