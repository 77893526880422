import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ImageDto, MediaType, ProfileDto, ProfileState, StoreDto } from 'src/app/api/wallet-api.generated';
import { ProfilesService } from 'src/app/shared/services/profiles/profiles.service';


@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit, OnChanges {

  @Input() cardData: ProfileDto | StoreDto;
  @Output() cardSelected: EventEmitter<ProfileDto | StoreDto>;
  profileStates = ProfileState;
  profile: ProfileDto;
  store: StoreDto;
  storeImage: ImageDto = {
    id: '',
    mediaType: MediaType.PNG,
    payload: ''
  };

  constructor(public profilesService: ProfilesService,) {
    this.cardSelected = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mapInputDataType();
  }

  ngOnInit(): void {

  }

  isProfile = (data: StoreDto | ProfileDto): data is ProfileDto => (this.cardData as ProfileDto).legalName !== undefined;

  mapInputDataType() {
    if (this.isProfile(this.cardData)) {
      this.profile = this.cardData as ProfileDto;
      this.store = null;
      this.storeImage = {
        id: '',
        mediaType: MediaType.PNG,
        payload: ''
      };
    }
    else {
      this.store = this.cardData as StoreDto;
      this.storeImage = {
        id: this.store.coverImageId,
        mediaType: this.store.coverImageMediaType,
        payload: ''
      };
      this.profile = null;
    }
  }

  onProfileClick(profile: ProfileDto) {
    this.cardSelected.emit(profile);
  }

  onStoreClick(store: StoreDto) {
    this.cardSelected.emit(store);
  }

}
