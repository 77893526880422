
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      return params.interpolateParams['default'] || params.key;
    }
    return params.key;
  }
}
