export const environment = {
  production: true,
  version: '2023.13.3',
  baseApiUrl: null,
  securedApis: [
    'https://localhost:5002',
    'https://decr-wallet.azurewebsites.net/',
    'https://ecred-wallet.azurewebsites.net/',
    'https://ecred-wallet-test.azurewebsites.net/',
    'https://ecs-wallet.azurewebsites.net/',
    'https://ecs-wallet-test.azurewebsites.net/',
    'https://wallet.ecredits.com/',
  ],
  menuHiddenBreakpoint: 768,
  adminRoot: '/',
  minimumAmount: 0.01,
  paymentReferenceValidation: {
    minLength: 2,
    maxLength: 64,
  },
  defaultBusinessLogoId: '22222222-2222-2222-2222-222222222222',
  //time to sleep between retries for getting payment status
  timeToSleep: 5,
  amountRegex: '^[0-9]*([.,][0-9]{1,8})?$',
  qrCodeWidth: {
    sm: 120,
    md: 256,
  },
  scannerVideoSize: {
    sm: {
      width: 187,
      height: 180,
    },
    md: {
      width: 374,
      height: 360,
    },
  },
  cashbackWorldName: 'Cashback World',
};
