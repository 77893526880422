import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionCardComponent } from './transaction-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeModule } from '../badge/badge.module';

@NgModule({
  declarations: [TransactionCardComponent],
  imports: [
    CommonModule,
    TranslateModule,
    BadgeModule,
  ],
  exports: [TransactionCardComponent],
})
export class TransactionCardModule { }
