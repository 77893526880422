import { HttpResponseBase } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class ApiClientBase {
  constructor() { }

  protected transformResult(
    url: string,
    response: HttpResponseBase,
    processor: (res: HttpResponseBase) => any
  ): Observable<any> {
    return processor(response);
  }
}
