import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ProcessedAuthCodes } from '../../enums/enums';
import { AuthService } from '../../../auth/auth.service';
import { LOGOUT_MARKER } from 'src/app/shared/constants/value';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const { status } = error;

          switch (true) {
            case status === ProcessedAuthCodes.notAuthenticated:
            case (status === ProcessedAuthCodes.unexpectedError) && request.url.includes('connect/token'): {
              this.authService.logoff();
              this.localStorageService.write(LOGOUT_MARKER, true);
              this.router.navigate(['/']);
              break;
            }
            default: {
              throwError(error);
              break;
            }
          }
          return throwError(error);
        }),
      ) as Observable<HttpEvent<unknown>>;
  }
}
