import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { LangService } from '../../services/lang/lang.service';
import { DecimalSeparatorSymbol, SupportedLanguagesCodes } from '../../enums/enums';


@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {

  @Input() allowDecimals = true;
  @Input() allowSign = false;

  decimalSeparator: string = getLocaleNumberSymbol(this.langService?.language ?? SupportedLanguagesCodes.english, NumberSymbol.Decimal);

  previousValue = '';

  integerUnsigned = '^[0-9]*$';
  integerSigned = '^-?[0-9]+$';
  decimalUnsigned = '^[0-9]+(.[0-9]+)?$';
  decimalSigned = '^-?[0-9]+(.[0-9]+)?$';

  constructor(private hostElement: ElementRef,
              private langService: LangService) {
  }

  @HostListener('change', ['$event']) onChange(e) {
    this.validateValue(this.hostElement.nativeElement.value);
  }

  @HostListener('paste', ['$event']) onPaste(e) {

    // get and validate data from clipboard
    const value = e.clipboardData.getData('text/plain');
    this.validateValue(value);
    e.preventDefault();
  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const cursorPosition: number = e.target['selectionStart'];
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const originalValue: string = e.target['value'];
    const key: string = this.getName(e);
    const controlOrCommand = (e.ctrlKey === true || e.metaKey === true);
    const signExists = originalValue.includes('-');
    const separatorExists = originalValue.includes(DecimalSeparatorSymbol.comma)
       || originalValue.includes(DecimalSeparatorSymbol.point);

    // allowed keys apart from numeric characters
    const allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab'
    ];

    // when decimals are allowed, add
    // decimal separator to allowed codes when
    // its position is not close to the the sign (-. and .-)
    const separatorIsCloseToSign = (signExists && cursorPosition <= 1);
    if (this.allowDecimals && !separatorIsCloseToSign && !separatorExists) {
      allowedKeys.push(DecimalSeparatorSymbol.comma, DecimalSeparatorSymbol.point);
    }

    // when minus sign is allowed, add its
    // key to allowed key only when the
    // cursor is in the first position, and
    // first character is different from
    // decimal separator
    const firstCharacterIsSeparator = (originalValue.charAt(0) !== this.decimalSeparator);
    if (this.allowSign && !signExists &&
      firstCharacterIsSeparator && cursorPosition === 0) {

      allowedKeys.push('-');
    }

    // allow some non-numeric characters
    if (allowedKeys.indexOf(key) !== -1 ||
      // Allow: Ctrl+A and Command+A
      (key === 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key === 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key === 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key === 'x' && controlOrCommand)) {
      // let it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.previousValue = originalValue;

    // allow number characters only
    const isNumber = (new RegExp(this.integerUnsigned)).test(key);
    if (isNumber) {
      return;
    } else {
      e.preventDefault();
    }
  }

  validateValue(value: string): void {
    const separatorExists = value.includes(DecimalSeparatorSymbol.comma)
      || value.includes(DecimalSeparatorSymbol.point);
    // choose the appropiate regular expression
    let regex: string;
    if (!this.allowDecimals && !this.allowSign) {
      regex = this.integerUnsigned;
    }

    if (!this.allowDecimals && this.allowSign) {
      regex = this.integerSigned;
    }

    if (this.allowDecimals && !this.allowSign) {
      regex = this.decimalUnsigned;
    }

    if (this.allowDecimals && this.allowSign) {
      regex = this.decimalSigned;
    }

    if (separatorExists) {
      value = this.swapSeparators(value);
    }

    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    const firstCharacter = value.charAt(0);
    if (firstCharacter === this.decimalSeparator) {
      value = 0 + value;
    }


    // when a numbers ends with a decimal separator,
    // fix it adding a zero in the end
    const lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter === this.decimalSeparator) {
      value = value + 0;
    }


    // test number with regular expression, when
    // number is invalid, replace it with a zero
    const valid: boolean = (new RegExp(regex)).test(value);
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.hostElement.nativeElement['value'] = valid ? value : '';
  }

  getName(e) {

    if (e.key) {

      return e.key;

    } else {
      // for old browsers
      if (e.keyCode && String.fromCharCode) {

        switch (e.keyCode) {
          case   8:
            return 'Backspace';
          case   9:
            return 'Tab';
          case  27:
            return 'Escape';
          case  37:
            return 'ArrowLeft';
          case  39:
            return 'ArrowRight';
          case 188:
            return ',';
          case 190:
            return '.';
          case 109:
            return '-'; // minus in numbpad
          case 173:
            return '-'; // minus in alphabet keyboard in firefox
          case 189:
            return '-'; // minus in alphabet keyboard in chrome
          default:
            return String.fromCharCode(e.keyCode);
        }
      }
    }
  }

  private swapSeparators(value: string): string {
    return this.decimalSeparator === DecimalSeparatorSymbol.comma ?
      value.replace(DecimalSeparatorSymbol.point, DecimalSeparatorSymbol.comma) :
      value.replace(DecimalSeparatorSymbol.comma, DecimalSeparatorSymbol.point);
  }

}
