import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationMessageTypes, } from '../../enums/enums';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {

  @Input()
  header: string;

  @Input()
  message: string;

  @Input()
  subMessage: string;

  @Input()
  messageType: ConfirmationMessageTypes;

  @Input()
  customHeader: boolean;
  @Input()
  buttonText: string;
  @Output()
  doneEvent: EventEmitter<any>;

  confirmationMessageTypes = ConfirmationMessageTypes;

  constructor() {
    this.doneEvent = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  onDone(event) {
    this.doneEvent.emit(event);
  }

  getImage(): string {
    switch (this.messageType) {
      case this.confirmationMessageTypes.success:
        return 'assets/icons/success.svg';
      case this.confirmationMessageTypes.waiting:
        return 'assets/images/eCredits-logo-animated.png';
      case this.confirmationMessageTypes.fail:
        return 'assets/icons/error.svg';
      default:
        return 'assets/images/eCredits-logo-animated.png';
    }
  }

}
