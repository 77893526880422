import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { GlobalConstants } from 'src/app/shared/common/constants';
import { Currency } from '../../api/wallet-api.generated';
import { CurrencyAmountInput } from '../models';

@Pipe({
  name: 'amountInputPlaceholder'
})
export class AmountInputPlaceholderPipe implements PipeTransform {

  transform(language: string, currency: Currency): string {
    if(!language || !currency) {
      return '';
    }

    const currencySettings: Map<Currency, CurrencyAmountInput> = GlobalConstants.currencyAmountInputSettings;

    const placeholderSetting = currencySettings.get(currency) || currencySettings.get(Currency.EUR);

    const separator = getLocaleNumberSymbol(language, NumberSymbol.Decimal) || placeholderSetting.placeholderSeparator;

    return `${placeholderSetting.placeholderPrefix}${separator}${placeholderSetting.placeholderSuffix}`;
  }

}
