<div class="col d-sm-flex justify-content-sm-center align-items-sm-center">
    <div id="qr-card" style="border-radius: 0px;">
        <div class="row">
            <div class="col d-flex justify-content-center">
                <h1 [translate]="'barcode-scanner.scan-title-message'" [translateParams]="{default: 'Scan the QR-code'}">
                </h1>
            </div>
        </div>
        <section id="qr-code-scan">
            <div class="row" style="margin-bottom: 12px;">
                <div class="col d-flex justify-content-center align-items-center">
                    <div id="qr-code" class="d-flex justify-content-center align-items-center"
                        style="background: url('assets/icons/QRBlueFrame.svg') center / contain no-repeat;">
                        <video id="video" [width]="videoWidth" [height]="videoHeight" style="border: 1px solid gray"></video>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-center align-items-center">
                    <div class="form-group" id="sourceSelectPanel" *ngIf="devices && devices.length > 0">
                        <!-- <label for="sourceSelect"
                            [translate]="'barcode-scanner.camera-label'"
                            [translateParams]="{default: 'Camera:'}">Camera:</label> -->
                        <select *ngIf="devices.length > 1" id="sourceSelect" style="max-width:400px" [(ngModel)]="selectedDeviceId"
                            (change)="onDeviceChange($event)">
                            <option *ngFor="let device of devices" [ngValue]="device.deviceId">{{device.label}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div *ngIf="!autoScan" class="row">
                <div class="col d-flex justify-content-center align-items-center">
                    <div class="form-group">
                        <button *ngIf="!isScanning" (click)="start()" class="btn btn-primary" [translate]="'barcode-scanner.start-button'"
                            [translateParams]="{default: 'Start'}"></button>
                        <button *ngIf="isScanning" (click)="stop()" class="btn btn-primary" [translate]="'barcode-scanner.stop-button'"
                            [translateParams]="{default: 'Stop'}"></button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-center align-items-center">
                    <h3>{{scannedString | translate}}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-center align-items-center">
                    <button *ngIf="qrCodeFound" (click)="reset()" class="btn btn-light" style="height: 25px; padding-top:3px"
                        [translate]="'barcode-scanner.reset-button'" [translateParams]="{default: 'Reset'}"></button>
                </div>
            </div>
        </section>
    </div>
</div>
