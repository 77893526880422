import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { ProfilesService } from '../shared/services/profiles/profiles.service';
import { ProfileDto } from '../api/wallet-api.generated';

@Injectable({
  providedIn: 'root'
})
export class ProfilesResolver implements Resolve<boolean> {

  constructor(private router: Router,
              private profilesService: ProfilesService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const profileSubjectValue: ProfileDto[] = this.profilesService.profilesSubject?.getValue();

    if(profileSubjectValue?.length) {
      return Promise.resolve();
    }

    return this.profilesService.getProfiles().toPromise()
      .then((profiles: ProfileDto[]) => {
        this.profilesService.setProfiles(profiles || []);
      })
      .catch(_ => {
        this.router.navigate(['profiles']);
        return Promise.resolve();
      });
  }
}
