import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../services/currency/currency.service';

@Pipe({
  name: 'ethToWei'
})
export class EthToWeiPipe implements PipeTransform {

  constructor(
    private readonly currencyService: CurrencyService
  ) { }

  transform(value: number): string {
    if (!value) {
      return '0';
    }

    return this.currencyService.ethToWei(value);
  }

}
