import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import en from '../../../../lang/generated/en.json';
import de from '../../../../lang/generated/de.json';
import it from '../../../../lang/generated/it.json';
import { getThemeLang, setThemeLang } from 'src/app/utils/util';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { SupportedLanguagesCodes, SupportedLocalesCodes } from '../../enums/enums';
import { NAVIGATOR } from '../../tokens/window';

const languageKey = '__lang';

export class Language {
  code: string;
  direction: string;
  label: string;
  shorthand: string;
}

@Injectable({
  providedIn: 'root',
})
export class LangService {
  isSingleLang = false;
  renderer: Renderer2;
  defaultLanguage = getThemeLang();
  supportedLanguages: Language[] = [
    {code: SupportedLanguagesCodes.english, direction: 'ltr', label: 'English', shorthand: SupportedLocalesCodes.english},
    {code: SupportedLanguagesCodes.german, direction: 'ltr', label: 'Deutsch', shorthand: SupportedLocalesCodes.german},
    {code: SupportedLanguagesCodes.italian, direction: 'ltr', label: 'Italian', shorthand: SupportedLocalesCodes.italian},
  ];

  constructor(
    private translate: TranslateService,
    private rendererFactory: RendererFactory2,
    @Inject(NAVIGATOR) readonly navigator: Navigator,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  init(): void {
    this.translate.setTranslation(SupportedLanguagesCodes.english, en);
    this.translate.setTranslation(SupportedLanguagesCodes.german, de);
    this.translate.setTranslation(SupportedLanguagesCodes.italian, it);
    this.translate.setDefaultLang(this.defaultLanguage);
    if (this.isSingleLang) {
      this.translate.use(this.defaultLanguage);
    } else {
      this.language = '';
    }
  }

  checkForDirectionChange(): void {
    this.renderer.removeClass(document.body, 'ltr');
    this.renderer.removeClass(document.body, 'rtl');
    this.renderer.addClass(document.body, this.direction);
    this.renderer.setAttribute(
      document.documentElement,
      'direction',
      this.direction
    );
  }

  set language(lang: string) {
    let language = lang || this.getBrowserLanguage();
    const isSupportedLanguage = this.supportedLanguages
      .map((item) => item.code)
      .includes(language);
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    if (
      lang !== '' &&
      this.supportedLanguages.map((item) => item.code).includes(lang) &&
      this.direction !==
      this.supportedLanguages.find((item) => item.code === lang).direction
    ) {
      setThemeLang(lang);
      window.location.reload();
    } else {
      this.translate.use(language);
    }
    this.checkForDirectionChange();
    setThemeLang(language);
  }

  get language(): string {
    return this.translate.currentLang;
  }

  get languageShorthand(): string {
    return this.supportedLanguages.find(
      (item) => item.code === this.translate.currentLang
    ).shorthand;
  }

  get direction(): string {
    return this.supportedLanguages.find(
      (item) => item.code === this.translate.currentLang
    ).direction;
  }

  get languageLabel(): string {
    return this.supportedLanguages.find(
      (item) => item.code === this.translate.currentLang
    ).label;
  }

  formatNumberLocalToUS(value: number): number {
    const decimalSymbol = getLocaleNumberSymbol(this.language, NumberSymbol.Decimal);

    const thousandSeparator = getLocaleNumberSymbol(this.language, NumberSymbol.CurrencyGroup);

    const newFormattedNumber = value.toString()
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSymbol), '.');

    return Number.parseFloat(newFormattedNumber);
  }

  getBrowserLanguage(): string {
    const browserLang = this.navigator.language;

    switch (browserLang.split('-')[0]) {
      case SupportedLocalesCodes.english:
        return SupportedLanguagesCodes.english;
      case SupportedLocalesCodes.german:
        return SupportedLanguagesCodes.german;
      case SupportedLocalesCodes.italian:
        return SupportedLanguagesCodes.italian;
      default:
        return SupportedLanguagesCodes.english;
    }
  }
}
