<ng-container *ngIf="isLoading$|async; then loader; else showData"></ng-container>

<ng-template #showData>
  <section class="profile-section" *ngIf="isAuthenticated$ | async">
    <router-outlet></router-outlet>
  </section>
</ng-template>

<ng-template #loader>
  <div class="panel loader-custom margin-top-20">
    <app-loader></app-loader>
  </div>
</ng-template>


