import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss'],
})
export class ProfilesComponent implements OnInit {

  public isAuthenticated$: Observable<boolean> = this.authService.isLoggedIn().pipe(
    map((isAuthenticated: boolean) => isAuthenticated),
  );

  public isLoading$: Observable<boolean> = this.isAuthenticated$.pipe(
    map(() => false),
    startWith(true),
  );

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

}
