import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AutoLoginComponent } from './auth/autologin/auto-login.component';
import { ForbiddenComponent } from './auth/forbidden/forbidden.component';
import { UnauthorizedComponent } from './auth/unauthorized/unauthorized.component';
import { AuthorizationGuard } from './shared/guards/authorization/authorization.guard';
import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { ProfilesComponent } from './profiles/profiles.component';
import { ProfilesResolver } from './profiles/profiles.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'profiles',
    pathMatch: 'full',
  },
  {
    path: 'profiles',
    component: ProfilesComponent,
    canActivate: [AuthorizationGuard],
    resolve: {
      profiles: ProfilesResolver,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesModule),
      },
    ],
  },
  { path: 'autologin', component: AutoLoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'scan', component: BarcodeScannerComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
