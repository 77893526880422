import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {YesNoDialogBoxComponent} from './yes-no-dialog-box.component';



@NgModule({
  declarations: [YesNoDialogBoxComponent],
  imports: [
    CommonModule
  ],
  exports: [YesNoDialogBoxComponent],
})
export class YesNoDialogBoxModule { }
