import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { SettingsClient, WALLET_API_URL } from './api/wallet-api.generated';
import { ForbiddenComponent } from './auth/forbidden/forbidden.component';
import { AutoLoginComponent } from './auth/autologin/auto-login.component';
import { UnauthorizedComponent } from './auth/unauthorized/unauthorized.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MissingTranslationHelper } from './shared/helpers/missing-translation-helper';
import usLocale from '@angular/common/locales/es-US';
import deLocale from '@angular/common/locales/de';
import itLocale from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { HttpErrorInterceptor } from './shared/interceptors/http-error/http-error.interceptor';
import { LogoutComponent } from './auth/logout/logout.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SupportedLocalesCodes } from './shared/enums/enums';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthConfigModule } from './auth/auth-config.module';
import { ProfilesComponent } from './profiles/profiles.component';
import { BarcodeScannerModule } from './barcode-scanner/barcode-scanner.module';
import { CashierShellComponent } from './cashier-shell/cashier-shell.component';
import { HeaderComponent } from './containers/header/header.component';

const getApiUrl = () => {
  const url = environment.baseApiUrl ?? window.location.origin;
  if (environment.production && url.indexOf('localhost') > 0) {
    // pwa running staging config
    return 'https://decr-wallet.azurewebsites.net';
  }
  return url.replace('cashier', 'wallet');
};

registerLocaleData(usLocale, SupportedLocalesCodes.unitedStates);
registerLocaleData(deLocale, SupportedLocalesCodes.german);
registerLocaleData(itLocale, SupportedLocalesCodes.italian);

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    AutoLoginComponent,
    UnauthorizedComponent,
    LogoutComponent,
    ProfilesComponent,
    CashierShellComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AuthConfigModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutContainersModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler, useClass: MissingTranslationHelper,
      },
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ToastContainerModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    SharedModule,
    AngularSvgIconModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BarcodeScannerModule,
  ],
  providers: [
    {
      provide: WALLET_API_URL,
      useValue: getApiUrl(),
    },
    SettingsClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
