/* eslint-disable no-shadow */
export enum BalanceTypes {
  eCredits = 'ecredits',
  eActivity = 'eactivity',
  euro = 'euro',
  ethereum = 'ether',
}

export enum NetworkName {
  eCredits = 'eCredits',
  ethereum = 'Ethereum'
}

export enum CurrencyCodes {
  eur = 'euro',
  ecs = 'ecredits',
  eth = 'ether'
}

export enum ConfirmationMessageTypes {
  success,
  fail,
  waiting,
}
export enum ClientSideTransactionType {
  cashback = 'Cashback',
  compensation = 'Compensation',
}

export enum SupportedLanguagesCodes {
  english = 'en-US',
  german = 'de-DE',
  italian = 'it-IT',
}

export enum TransactionsStatuses {
  confirmed = 'Confirmed',
  failed = 'Failed',
}

export enum ProcessedAuthCodes {
  unexpectedError = 400,
  notAuthenticated = 401,
  notAuthorized = 403,
  badRequest = 404,
  authenticationTimeout = 419,
  loginTimeout = 440,
  requestError = 0,
  requestConflict = 409,
  disconnect = 410,
}

export enum UserRoles {
  owner = 'o',
  manager = 'm',
  viewer = 'v',
  cashier = 'c',
  developer = 'd',
}

export enum ApplicationFeatures {
  ambassador = 'Ambassador',
  webWidget = 'WebWidget',
  sceMembership = 'SCEMembership',
  businessOnBoarding = 'BusinessOnboarding',
  userInvitations = 'UserInvitations',
  subscriptions = 'Subscriptions',
  refundRequests = 'Refunds',
  userManagement = 'UserManagement',
  priorityPointsAirdrop = 'PriorityPointsAirdrop',
  receivePayments = 'ReceivePayments',
  addressBook = 'AddressBook',
  deleteAccount = 'DeleteAccount',
  cashback = 'Cashback',
  refundableAmount = 'RefundableAmount',
  ethIntegration = 'ETHIntegration',
}

export enum SupportedLocalesCodes {
  unitedStates = 'us',
  german = 'de',
  italian = 'it',
  britain = 'en-gb',
  english = 'en',
}


export enum DecimalSeparatorSymbol {
  comma = ',',
  point = '.'
}

export enum EnterpriseSubscriptionType {
  basic = 'Basic',
  premium = 'Premium'
}
