// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DateUtils {
  export const format = (date: Date): Date => {
    const offset = date.getTimezoneOffset();
    return new Date(date.getTime() - offset * 60 * 1000);
  };

  export const formatToIsoString = (date: Date): string => {
    const offset = date.getTimezoneOffset();
    const tzDate = new Date(date.getTime() - offset * 60 * 1000);
    return tzDate.toISOString().split('T')[0];
  };

  export const decreaseDateWithOffset = (date = new Date(), offset: number = 1): Date => {
    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() - offset);

    return newDate;
  };

  export const resetDateTime = (date = new Date()) => {
    const newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);

    return newDate;
  };

  export const increaseDateWithOffset = (date = new Date(), offset: number = 1): Date => {
    date.setDate(date.getDate() + offset);

    return date;
  };

  export const addTimeZoneOffset = (date = new Date()) => {
    const newDate = new Date(date);

    const offset = newDate.getTimezoneOffset();

    return date ? new Date(newDate.getTime() + offset * 60 * 1000) : undefined;
  };

  export const checkSubscriptionExpiration = (targetDate: Date, dateToCompare: Date = new Date()) => {
    const resetedTargetDate = DateUtils.resetDateTime(DateUtils.addTimeZoneOffset(new Date(targetDate)));
    const resetedDateToCompare = DateUtils.resetDateTime(dateToCompare);

    return resetedTargetDate >= resetedDateToCompare;
  };
}
