export const AUTH_ATTEMPT = 'AUTH_ATTEMPT';
export const PATH_AUTOLOGIN = '/autologin';
export const UNAUTHORIZED = 'unauthorized';
export const REDIRECT = 'redirect_url';
export const LOGOUT_MARKER = 'logout_marker';

export const ACTIVE = 'active';
export const INCOMPLETE = 'incomplete';
export const CONFIRMED = 'confirmed';
export const FAILED = 'failed';

export const ECREDITS_IN = 'ecredits-in';
export const ECREDITS_OUT = 'ecredits-out';
export const EACTIVITY_IN = 'eactivity-in';
export const EACTIVITY_OUT = 'eactivity-out';
export const CASHBACK_IN = 'cashback-in';

export const ALLOW_ALL_FEATURES = 'allow_all_features';
export const PREFERRED_CURRENCIES = 'preferred_currencies';

export const COMPANY_NAME = 'The People´s SCE';

export const DEFAULT_REQUEST_TEMPLATE_TIMER = 5000;
export const DEFAULT_REQUEST_TEMPLATE_TRANSACTIONS = 1;
