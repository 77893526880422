import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, shareReplay, take, tap } from 'rxjs/operators';

import {
  SettingsClient as WalletSettingsClient,
  ClientAppSettingsDto as WalletClientAppSettingsDto,
  UsersClient,
  UserCurrencySettingsDto,
} from '../../../api/wallet-api.generated';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  public static authServiceUrl = '';
  public static settingsLoaded = false;
  public static profileImagesLocation = '';
  public static learningCenterUrl: string;
  public static ecreditsTradingPairOnBlocktrade: number;
  public static etherTradingPairOnBlocktrade: number;
  public static disabledFeatures: string[] = [];
  public static blocktradeDomain: string;
  public static currencyRateSource: string;
  public static supportedFiatCurrencyCodes: string[] = [];

  private walletClientSettings: WalletClientAppSettingsDto;

  private currencysettings: BehaviorSubject<UserCurrencySettingsDto> = new BehaviorSubject(null);
  constructor(
    private readonly usersClient: UsersClient,
  ) {}

  get currencySettings$(): Observable<UserCurrencySettingsDto> {
    return this.currencysettings.pipe();
  }

  set currencySettings(value: UserCurrencySettingsDto) {
    this.currencysettings.next(value);
  }


  public static loadSettings$(settingsClient: WalletSettingsClient) {
    return settingsClient.clientAppSettings().pipe(
      tap((cfg: WalletClientAppSettingsDto) => {
        this.authServiceUrl = cfg.authIssuerUrl;
        this.settingsLoaded = true;
        this.profileImagesLocation = cfg.profileImagesLocation;
        this.learningCenterUrl = cfg.learningCenterUrl;
        const [, , ecsEurTradingPair, , , , , ethEurTradingPair] = cfg.blocktradeConfig.split(':');
        this.ecreditsTradingPairOnBlocktrade = +ecsEurTradingPair;
        this.etherTradingPairOnBlocktrade = +ethEurTradingPair;
        this.disabledFeatures = cfg.disabledFeatures;
        this.blocktradeDomain = cfg.blocktradeDomain;
        this.currencyRateSource = cfg.currencyRateSource;
        this.supportedFiatCurrencyCodes = cfg.supportedFiatCurrencyCodes;
      })
    );
  }

  getCurrencySettings(): Observable<UserCurrencySettingsDto> {
    return this.usersClient.getCurrencySettings().pipe(
      tap((data: UserCurrencySettingsDto) => (this.currencySettings = data)),
      take(1),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError((e) => throwError(e))
    );
  }
}
