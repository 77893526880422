import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StoreDto, StoresClient } from 'src/app/api/wallet-api.generated';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  public stores$: Observable<StoreDto[]>;

  private storesSubject: BehaviorSubject<StoreDto[]> = new BehaviorSubject(null);

  constructor(private storesClient: StoresClient) { }

  get storesObservable$() {
    return this.storesSubject.pipe();
  }

  public getStores$(profileId: string): Observable<StoreDto[]> {
    this.stores$ = this.storesClient.getStores(profileId);
    return this.stores$;
  }

  public getStoreDetails$(profileId: string, storeId: string): Observable<StoreDto> {
    if (this.stores$) {
      return this.stores$.pipe(map(x => this.filterStores(x, storeId)));
    }
    return this.getStores$(profileId).pipe(map(x => this.filterStores(x, storeId)));
  }

  public setStores(stores: StoreDto[]) {
    this.storesSubject.next(stores);
  }

  protected filterStores(stores: StoreDto[], storeid: string): StoreDto {
    return stores.find(x => x.id === storeid);
  }
}
