<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a class="navbar-logo" [routerLink]="adminRoot">
      <span class="logo d-none d-xs-block ml-3"></span>
      <span class="logo-mobile d-block d-xs-none ml-3"></span>
    </a>
  </div>


  <ng-container *ngIf="$isMobile | async; else desktopMenu">
    <div class="navbar-right no-print" [class.active]="isSettingsOpened">
      <ng-template [ngTemplateOutlet]="toggleBtn"></ng-template>
    </div>
    <div class="navbar__nav-container" [class.hide]="!isSettingsOpened" (click)="toggleNav()">
      <div class="navbar__nav-menu-container">
        <ng-template [ngTemplateOutlet]="menuContent"></ng-template>
      </div>
    </div>
  </ng-container>
</nav>


<ng-template #toggleBtn>
  <button class="cog-wrapper__btn btn btn-empty p-0" type="button"  (click)="toggleNav()">
    <i #toggleButton class="fas fa-cog cog-icon"></i>
  </button>
</ng-template>


<ng-template #desktopMenu>
  <div class="navbar-right no-print" [class.active]="isSettingsOpened">
    <ng-template [ngTemplateOutlet]="toggleBtn"></ng-template>

    <div class="navbar-right__cog-wrapper cog-wrapper d-inline-block" dropdown>
      <div #menu class="dropdown-menu dropdown-menu-right cog-wrapper__setting-dropdown setting-dropdown" role="menu"
           (mouseleave)='isSettingsOpened = false' [class.hide]="!isSettingsOpened">
        <ng-template [ngTemplateOutlet]="menuContent"></ng-template>
      </div>
    </div>
  </div>

</ng-template>


<ng-template #menuContent>
  <div class="setting-dropdown__user-name">{{ userName$ | async }}</div>
  <a class="dropdown-item c-pointer" href="#" [routerLink]="['profiles']" [translate]="'setting-menu.profiles'"
     [translateParams]="{default: 'Profiles'}"></a>
  <li class="divider dropdown-divider setting-dropdown__divider"></li>
  <a class="dropdown-item c-pointer setting-dropdown__learning-center" [href]="learningCenterUrl" target="_blank"
     [translate]="'setting-menu.learning-center-link'" [translateParams]="{default: 'Learning Center'}"></a>
  <a class="dropdown-item c-pointer setting-dropdown__sign-out" (click)="onSignOut($event)"
     [translate]="'setting-menu.sign-out'" [translateParams]="{default: 'Log out'}"></a>
</ng-template>

