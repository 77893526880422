<div class="app-container" >
    <app-topnav *ngIf="isAuthenticated$ | async"></app-topnav>

    <div class="main-container">
        <div class="main-container__wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="footer-container" *ngIf="isAuthenticated$ | async">
        <app-footer [version]="version"></app-footer>
    </div>
</div>
