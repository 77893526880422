import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Apps, ImageDto, ProfileDto, ProfilesClient } from '../../../api/wallet-api.generated';
import { AuthService, AuthState } from '../../../auth/auth.service';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { DateUtils } from '../../../utils/date.utils';
import { EnterpriseSubscriptionType } from '../../enums/enums';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  public profiles$: Observable<ProfileDto[]> = this.authService.authState$.pipe(
    switchMap((state: AuthState) => this.retrieveProfiles(state)),
    shareReplay({refCount: true, bufferSize: 1}),
  );

  public profileImageBasePath: string = AppSettingsService.profileImagesLocation + '/';

  public defaultImgPath: string = AppSettingsService.profileImagesLocation + '/' + environment.defaultBusinessLogoId + '.PNG';

  public profileSubject: BehaviorSubject<ProfileDto[]> = new BehaviorSubject(null);

  constructor(private authService: AuthService,
              private profilesClient: ProfilesClient) {}

  get profilesObservable$() {
    return this.profileSubject.pipe();
  }

  get profilesSubject() {
    return this.profileSubject;
  }

  public getProfiles() {
    return this.profilesClient.getProfiles(Apps.Cashier);
  }

  public setProfiles(profiles: ProfileDto[]) {
    this.profileSubject.next(profiles);
  }

  public onImgError(event) {
    event.target.src = this.defaultImgPath;
  }

  public getImageUrl(image: ImageDto): string {
    if (image) {
      return `${this.profileImageBasePath}${image.id}.${image.mediaType}`;
    }

    return this.defaultImgPath;
  }

  public retrieveSelectedProfile(profiles: ProfileDto[], businessProfileId: string): ProfileDto {
    return profiles.filter((x) => x.isBusiness === true && x.id === businessProfileId)[0];
  }

  public getSelectedProfile(id: string): Observable<ProfileDto> {
    return this.profilesObservable$.pipe(
      filter(data => !!data),
      map(data => data.find(profile => profile?.id === id))
    );
  }

  public checkForActivePremiumSub(profile: ProfileDto) {
    return profile.mainSubscription === EnterpriseSubscriptionType.premium
      && DateUtils.checkSubscriptionExpiration(profile.mainSubscriptionValidUntil);
  }

  protected retrieveProfiles(authState: AuthState): Observable<ProfileDto[]> {
    if (authState === AuthState.Authenticated) {
      return this.profilesClient.getProfiles(Apps.Cashier);
    }
    return of(null);
  }
}
