import { Injectable } from '@angular/core';
import Big from 'big.js';

import { GlobalConstants } from '../../common/constants';

@Injectable()
export class CurrencyService {

  public ethToWei(etherAmount: number): string {
    return Big(GlobalConstants.etherInWei).times(etherAmount).toString();
  }

  public weiToEth(weiAmount: string): number {
    return Big(weiAmount).div(GlobalConstants.etherInWei).toNumber();
  }

}
