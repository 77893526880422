import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';

export const WINDOW_WIDTH_BREAKPOINTS = {
  xl: 2560,
  lg: 1530,
  md: 1024,
  sm: 600,
  xs: 320
};

@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  currentWidth: number  = window.innerWidth;
  readonly windowSizeSubject = new BehaviorSubject<number>(this.currentWidth);
  readonly isMobileSubject = new BehaviorSubject<boolean>(this.isMobile(this.currentWidth));
  public size$: Observable<number> = this.windowSizeSubject.asObservable();
  public isMobile$: Observable<boolean> = this.isMobileSubject.asObservable();

  private resize$: Observable<any>;


  constructor() {
    this.initialize();
  }

  private initialize(): void {
    this.resize$ = fromEvent(window, 'resize')
      .pipe(
        debounceTime(200),
        map(() => window.innerWidth),
        distinctUntilChanged(),
        startWith(window.innerWidth),
        tap(width => this.setWindowWidth(width)),
      );

    this.resize$.subscribe();
  }

  private setWindowWidth(width: number): void {
    this.currentWidth = width;

    this.isMobileSubject.next(this.isMobile(width));
    this.windowSizeSubject.next(width);
  }

  private isMobile(width: number): boolean {
    return width < WINDOW_WIDTH_BREAKPOINTS.sm;
  }

}
