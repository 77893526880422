import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '../../tokens/local-storage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(LOCAL_STORAGE) readonly localStorage: Storage) { }

  public read(key: string): any {
    const data = this.localStorage.getItem(key);
    if (data != null) {
      return JSON.parse(data);
    }
    return;
  }

  public write(key: string, value: any): void {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  public remove(key: string): void {
    this.localStorage.removeItem(key);
  }
}


