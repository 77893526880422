import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CookieManagerService } from '../shared/services/cookie-manager/cookie-manager.service';
import { ClientAppSettingsDto, SettingsClient } from '../api/wallet-api.generated';
import { AppSettingsService } from '../shared/services/app-settings/app-settings.service';
import { map, switchMap } from 'rxjs/operators';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';

/*ToDo disable updated config until we have stable version*/
/*export const httpLoaderFactory = (settingsClient: SettingsClient) => {
  const config$ = AppSettingsService.loadSettings$(settingsClient).pipe(
    map((cfg: ClientAppSettingsDto) => ({
        authority: cfg.authIssuerUrl,
        redirectUrl: window.location.origin,
        clientId: 'cashier',
        responseType: 'code',
        scope: 'openid profile wallet auth offline_access',
        postLogoutRedirectUri: window.location.origin,
        startCheckSession: false, // deactived due to issues with logout in incognito windows & after pw reset
        silentRenew: true,
        silentRenewUrl: window.location.origin + '/silent-renew.html',
        postLoginRoute: '/',
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/unauthorized',
        logLevel: 0, // LogLevel.Debug,
        maxIdTokenIatOffsetAllowedInSeconds: 30,
        historyCleanupOff: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        // autoUserinfo: false,
      })
    )
  );

  return new StsConfigHttpLoader(config$);
};*/

export const configureAuth = (
  oidcConfigService: OidcConfigService,
  settingsClient: SettingsClient
) => {
  const setupAction$ = AppSettingsService.loadSettings$(settingsClient).pipe(
    map((cfg) => ({
      stsServer: cfg.authIssuerUrl,
      redirectUrl: window.location.origin,
      clientId: 'cashier',
      responseType: 'code',
      scope: 'openid profile wallet auth offline_access',
      postLogoutRedirectUri: window.location.origin,
      startCheckSession: false, // deactived due to issues with logout in incognito windows & after pw reset
      silentRenew: true,
      silentRenewUrl: window.location.origin + '/silent-renew.html',
      postLoginRoute: '/',
      forbiddenRoute: '/forbidden',
      unauthorizedRoute: '/unauthorized',
      logLevel: 0, // LogLevel.Debug,
      maxIdTokenIatOffsetAllowedInSeconds: 30,
      historyCleanupOff: true,
      useRefreshToken: true,
      renewTimeBeforeTokenExpiresInSeconds: 10,
      // autoUserinfo: false,
    })),
    // tap((cfg) => console.log(cfg)),
    switchMap((cfg) => oidcConfigService.withConfig(cfg))
  );

  return () => setupAction$.toPromise();
};


@NgModule({
  imports: [
    /*ToDo disable updated config until we have stable version*/
/*    AuthModule.forRoot({
      storage: CookieManagerService,
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [SettingsClient],
      },
      config: {},
    } as PassedInitialConfig),*/
    AuthModule.forRoot(),
  ],
  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService, SettingsClient],
      multi: true,
    },
  ],
  exports: [AuthModule],
})
export class AuthConfigModule { }
