import { Component, OnInit } from '@angular/core';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-auto-login',
  template: '<div class="panel"><app-loader></app-loader></div>',
})
export class AutoLoginComponent implements OnInit {
  lang: any;

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.authService.checkAuth().subscribe(() => this.authService.authorize(null));
  }
}
