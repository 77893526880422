<div class="business-data">
    <img class="business-data__business-logo rounded-circle" src={{logoUrl}} (error)="onImgError($event)" />
    <div class="business-data__business-name">
        {{businessProfile?.legalName}}
    </div>
    <div *ngIf="store$|async as store" class="business-data__store-name-wrapper store-name-wrapper">
        <div class="store-name-wrapper__store-name">
            {{store.name}}
        </div>
        <div class="store-name-wrapper__store-internal-name">
            {{store.internalName}}
        </div>
    </div>
</div>