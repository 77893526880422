<div class="confirmation-box" id="confirmation-page">
    <div class="confirmation-box__action-card action-card">
        <div class="confirmation-box__status-image">
            <img [src]="getImage()" />
        </div>
        <div class="action-card__status-header">
            <ng-container *ngIf="customHeader; else standardHeader">{{header|translate}}</ng-container>
            <ng-template #standardHeader>
                <span *ngIf="messageType==confirmationMessageTypes.success" [translate]="'confirmation-box.default-success-header'"
                    [translateParams]="{default: 'Success!'}"></span>
                <span *ngIf="messageType==confirmationMessageTypes.fail" [translate]="'confirmation-box.default-failed-header'"
                    [translateParams]="{default: 'Failed!'}"></span>
            </ng-template>
        </div>
        <div class="action-card__status-message">{{message|translate}}</div>
        <div class="action-card__status-sub-message">{{subMessage|translate}}</div>
    </div>
    <div class="confirmation-box__button-container">
        <ng-container *ngIf="buttonText; else standardButton">
            <button id="cashier-btn" class="btn btn-primary cashier-btn confirmation-box__button" type="button" (click)="onDone($event)">
                {{buttonText|translate}}
            </button>
        </ng-container>
        <ng-template #standardButton>
            <button id="cashier-btn" class="btn btn-primary cashier-btn confirmation-box__button" type="button" (click)="onDone($event)"
                [translate]="'confirmation-box.done-button'" [translateParams]="{default: 'Done'}">
            </button>
        </ng-template>
    </div>
</div>
