import { CreatePaymentRequestDto, Currency, PaymentSegmentDtoV2 } from 'src/app/api/wallet-api.generated';
import { CurrencyCodes, NetworkName } from '../enums/enums';
import { RequestDetails, CashierNetwork, CurrencyAmountInput } from '../models/';

export class GlobalConstants {
    public static currencyCodePrefix = 'currency-codes.';

    public static networks: CashierNetwork[] = [
      {
        name: NetworkName.eCredits,
        id: 1,
        currency: Currency.ECS,
        availableCurrencies: [CurrencyCodes.ecs, CurrencyCodes.eur]
      },
      {
        name: NetworkName.ethereum,
        id: 2,
        currency: Currency.ETH,
        availableCurrencies: [CurrencyCodes.eth, CurrencyCodes.eur]
      }
    ];


    public static currencyAmountInputSettings: Map<Currency, CurrencyAmountInput>
    = new Map<Currency, CurrencyAmountInput>([
      [Currency.EUR, {
        decimalPlaces: 2,
        decimalStep: 0.01,
        placeholderPrefix: '0',
        placeholderSuffix: '00',
        placeholderSeparator: '.'
      }],
      [Currency.ECS, {
        decimalPlaces: 2,
        decimalStep: 0.01,
        placeholderPrefix: '0',
        placeholderSuffix: '00',
        placeholderSeparator: '.'
      }],
      [Currency.ETH, {
        decimalPlaces: 6,
        decimalStep: 0.000001,
        placeholderPrefix: '0',
        placeholderSuffix: '000000',
        placeholderSeparator: '.'
      }]
    ]);

    public static etherInWei = '1000000000000000000';

    public static defaultFractionDigits = 2;
    public static defaultETHFractionDigits = 6;
}


export class DefaultValues {
  public static requestDetails: RequestDetails  = {
    amount: 0,
    fiatAmount: 0,
    selectedCurrency: null,
    paymentReference: '',
    targetCurrency: null,
  };

  public static paymentRequest: CreatePaymentRequestDto = {
    id: '',
    amountDue: 0,
    fiatAmount: 0,
    paymentReference: '',
    profileId: '',
    segmentNumber: 0,
    requestedInFiat: true,
    fiatCurrencyCode3: CurrencyCodes.eur,
    storeId: '',
    targetCurrency: ''
  };

  public static defaultSegment: PaymentSegmentDtoV2 = {
    segmentNumber: -1,
    name: '',
    isDefault: false,
    cashback: 0,
  };

  public static defaultSegmentName = 'payment-request-form.segment-option-not-applicable';
}

