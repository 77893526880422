import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forbidden',
  template: '<div>403: You have no rights to access this.</div>',
})
export class ForbiddenComponent implements OnInit {
  public message: string;
  public values: any[] = [];

  constructor() {
    this.message = 'ForbiddenComponent constructor';
  }

  ngOnInit() {}
}
