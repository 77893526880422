import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoTransactionComponent } from './components/no-transaction/no-transaction.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { BusinessDataComponent } from './components/business-data/business-data.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CurrencyScalePipe } from './pipes/currency-scale.pipe';
import { TransactionCardModule } from './components/transaction-card/transaction-card.module';
import { YesNoDialogBoxModule } from './components/yes-no-dialog-box/yes-no-dialog-box.module';
import { ScreenService } from './services/screen-service/screen.service';
import { EthToWeiPipe } from './pipes/eth-to-wei.pipe';
import { WeiToEthPipe } from './pipes/wei-to-eth.pipe';
import { CurrencyService } from './services/currency/currency.service';
import { AmountInputPlaceholderPipe } from './pipes/amount-input-placeholder.pipe';
import { NumbersOnlyDirective } from './directives/numbers-only/numbers-only.directive';
import { FilterOutDealsPipe } from './pipes/filter-out-deals/filter-out-deals.pipe';

const customComponents = [
  NoTransactionComponent,
  ProfileCardComponent,
  ConfirmationBoxComponent,
  ProfileCardComponent,
  BusinessDataComponent,
  LoaderComponent,
];

const customDirectives = [
  CurrencyScalePipe,
  EthToWeiPipe,
  WeiToEthPipe,
  AmountInputPlaceholderPipe,
  NumbersOnlyDirective,
  FilterOutDealsPipe,
];

@NgModule({
  declarations: [
    customComponents,
    customDirectives,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TransactionCardModule,
    YesNoDialogBoxModule,
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    TransactionCardModule,
    customComponents,
    customDirectives,
    BusinessDataComponent,
    LoaderComponent,
    CurrencyScalePipe,
    YesNoDialogBoxModule,
  ],
  providers: [customDirectives, ScreenService, CurrencyService]
})
export class SharedModule { }
