import { Pipe, PipeTransform } from '@angular/core';
import { DealDto } from '../../../api/wallet-api.generated';

@Pipe({
  name: 'filterOutDeals'
})
export class FilterOutDealsPipe implements PipeTransform {

  transform(value: DealDto[], ...args: unknown[]): DealDto[] {
    const today = new Date();
    const resetedTodayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);

    return value?.filter(item => {
      const end = new Date(item.toDate);
      const resetedEndDate = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0);

      return +resetedEndDate >= +resetedTodayDate;
    });
  }

}
