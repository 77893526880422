import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { LangService, Language } from 'src/app/shared/services/lang/lang.service';
import { AuthService, AuthState } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { LOGOUT_MARKER } from 'src/app/shared/constants/value';
import { AppSettingsService } from 'src/app/shared/services/app-settings/app-settings.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ScreenService } from 'src/app/shared/services/screen-service/screen.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent implements OnInit, OnDestroy {
  @ViewChild('menu') menuContainer: ElementRef;

  adminRoot = environment.adminRoot;
  subscription: Subscription | undefined;
  displayName$: Observable<string> | undefined;
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  showSidebarMenuBurger = true;
  learningCenterUrl: string;

  public isAuthenticated$: Observable<boolean> = this.authService.authState$.pipe(
    map((authState) => authState === AuthState.Authenticated)
  );

  public userName$: Observable<string> = this.authService.userData$.pipe(
    map((userDataResult: any) => userDataResult?.name),
  );

  isSettingsOpened = false;

  $isMobile = this.screenService.isMobile$;



  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private langService: LangService,
    private localStorageService: LocalStorageService,
    private screenService: ScreenService
  ) {
    langService.init();
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.learningCenterUrl = AppSettingsService.learningCenterUrl;
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event: any): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang: any): void {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  async ngOnInit(): Promise<void> {
    if (await this.authService.isLoggedIn) {
      this.displayName$ = this.authService.userData$.pipe(
        map((userDataResult: any) => userDataResult?.name),
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);
  };

  onSignOut(e: { stopPropagation: () => void }): void {
    console.log(`SignOut ${JSON.stringify(e)}`);
    if (e) {
      e.stopPropagation();
    }
    this.authService.logoff();
    this.localStorageService.write(LOGOUT_MARKER, true);
    this.router.navigate(['/']); // note: this is required for successful logout from subpages!
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: any): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';

  }

  toggleNav() {
    this.isSettingsOpened = !this.isSettingsOpened;
  }
}
