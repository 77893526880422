<div *ngIf="profile" class="profile-card" (click)="onProfileClick(profile)">
    <img class="rounded-circle light-shadow profile-card__profile-image" [src]="profilesService.getImageUrl(profile.profileImage)"
        (error)="profilesService.onImgError($event)">
    <div class="profile-card__profile-name profile-name">
        {{profile.displayName}}
    </div>
</div>

<div *ngIf="store" class="profile-card" (click)="onStoreClick(store)">
    <img class="rounded-circle light-shadow profile-card__store-image" [src]="profilesService.getImageUrl(storeImage)"
        (error)="profilesService.onImgError($event)">
    <div class="profile-card__store-name-wrapper store-name-wrapper">
        <div class="store-name-wrapper__store-name">
            {{store.name}}
        </div>
        <div class="store-name-wrapper__store-internal-name">
            {{store.internalName}}
        </div>
    </div>
</div>