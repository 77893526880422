import { Injectable } from '@angular/core';
import {
  OidcSecurityService,
  TokenHelperService,
} from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// eslint-disable-next-line no-shadow
export enum AuthState {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NotAuthenticated = 0,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IncompleteRegistration,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Authenticated,
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly userData$: Observable<any> = this.oidcSecurityService.userData$;

  public readonly authState$: Observable<AuthState> = this.oidcSecurityService.isAuthenticated$.pipe(
    map((val: any) => this.mapToAuthState(val))
  );

  public readonly checkSessionChanged$: Observable<boolean> = this.oidcSecurityService.checkSessionChanged$.pipe(
    tap((val) => console.log(`checkSessionChanged: ${val}`))
  );

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private tokenHelper: TokenHelperService) {}

  isLoggedIn(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$;
  }

  getToken(): string {
    return this.oidcSecurityService.getToken();
  }

  getTokenPayload(): any {
    return this.tokenHelper.getPayloadFromToken(this.getToken(), false);
  }

  userData(): Observable<any> {
    return this.oidcSecurityService.userData$;
  }

  checkAuth(): Observable<AuthState> {
    return this.oidcSecurityService
      .checkAuth()
      .pipe(map((val: any) => this.mapToAuthState(val)));
  }

  logoff() {
    this.oidcSecurityService.logoff(null);
  }

  revokeAccessToken() {
    return this.oidcSecurityService.revokeAccessToken();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  authorize(authOptions: { customParams: { ui_locales: string } } | undefined) {
    this.oidcSecurityService.authorize(authOptions);
  }

  refreshSession(): Observable<{ idToken: string; accessToken: string }> {
    return this.oidcSecurityService.forceRefreshSession();
  }

  private mapToAuthState(isAuthenticated: boolean) {
    if (isAuthenticated) {
      const token = this.tokenHelper.getPayloadFromToken(
        this.getToken(),
        false,
      );

      return !token.incomplete
        ? AuthState.Authenticated
        : AuthState.IncompleteRegistration;
    } else {
      return AuthState.NotAuthenticated;
    }
  }
}
