import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../services/currency/currency.service';

@Pipe({
  name: 'weiToEth'
})
export class WeiToEthPipe implements PipeTransform {

  constructor(
    private readonly currencyService: CurrencyService
  ) { }

  transform(value: string | number): number {
    if (!value) {
      return 0;
    }
    return this.currencyService.weiToEth(value.toString());
  }

}
