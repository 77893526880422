import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MediaType, ProfileDto, StoreDto } from '../../../api/wallet-api.generated';
import { ProfilesService } from '../../services/profiles/profiles.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings/app-settings.service';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { StoresService } from '../../services/stores/stores.service';

@Component({
  selector: 'app-business-data',
  templateUrl: './business-data.component.html',
  styleUrls: ['./business-data.component.scss'],
})
export class BusinessDataComponent implements OnInit, OnDestroy {

  bussinessProfileId: string;

  businessProfile: ProfileDto;

  logoUrl: string;
  defaultImgPath: string =
    AppSettingsService.profileImagesLocation +
    '/' +
    environment.defaultBusinessLogoId +
    '.' +
    MediaType.PNG;
  destroy$ = new Subject();
  storeId: string;
  store$: Observable<StoreDto>;;

  constructor(
    private profilesService: ProfilesService,
    private route: ActivatedRoute,
    private storesService: StoresService,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.initRouteSubscription();
  }

  initRouteSubscription() {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        this.bussinessProfileId = params.profile;
        this.storeId = params.store;
        if (this.bussinessProfileId) {
          this.loadBusinessData();
          if (this.storeId && this.storeId !== this.bussinessProfileId) {
            this.loadStoreName();
          }
        }
      });
  }

  loadStoreName() {
    this.store$ = this.storesService.getStoreDetails$(this.bussinessProfileId, this.storeId);
  }

  loadBusinessData() {
    this.profilesService.profiles$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.businessProfile = this.profilesService.retrieveSelectedProfile(
            data, this.bussinessProfileId
          );
          this.setLogoUrl();
        }
      });
  }



  setLogoUrl() {
    if (this.businessProfile) {
      if (this.businessProfile.profileImage) {
        this.logoUrl =
          AppSettingsService.profileImagesLocation +
          '/' +
          this.businessProfile.profileImage.id +
          '.' +
          this.businessProfile.profileImage.mediaType;
      } else {
        this.logoUrl = this.defaultImgPath;
      }
    }
  }

  onImgError(event) {
    event.target.src = this.defaultImgPath;
  }
}
