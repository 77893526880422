import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Currency, UserCurrencySettingsDto } from 'src/app/api/wallet-api.generated';
import { GlobalConstants } from '../common/constants';
import { LangService } from '../services/lang/lang.service';

@Pipe({
    name: 'currencyScale'
})

export class CurrencyScalePipe implements PipeTransform {
    language: string;

    constructor(private langService: LangService) {
        this.language = langService.language ?? langService.defaultLanguage;
    }

    transform(value: any, currency: Currency | string, settings: UserCurrencySettingsDto) {
        if (!value) {
            value = 0;
        }

        let formatedNumber;
        const numberString: string = value.toString();
        const maxFractionDigits = this.getSelectedCurrencySetting(currency as Currency, settings);
        const decimalDelimiterPosition = numberString.indexOf('.');
        const numberStringArray = numberString.split('.');
        if (decimalDelimiterPosition === -1 || numberStringArray[1].length <= maxFractionDigits) {
            formatedNumber = value;
        }
        else {
            formatedNumber = numberString.substring(0, decimalDelimiterPosition + maxFractionDigits + 1);
        }

        return formatNumber(Number.parseFloat(formatedNumber), this.language, `.${maxFractionDigits}-99`);
    }

    getSelectedCurrencySetting(currency: Currency, settings: UserCurrencySettingsDto): number {
        if (!settings) {
            return currency === Currency.ETH ? GlobalConstants.defaultETHFractionDigits : GlobalConstants.defaultFractionDigits;
        }

        const selectedCurrencySetting = settings?.currencySettings?.find(x => x.currency === currency);

        if (selectedCurrencySetting?.maxFractionDigits) {
            return selectedCurrencySetting.maxFractionDigits;
        }

        return currency === Currency.ETH ? GlobalConstants.defaultETHFractionDigits : GlobalConstants.defaultFractionDigits;
    }
}
