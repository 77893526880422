import { Component, Input } from '@angular/core';
import { COMPANY_NAME } from '../../../shared/constants/value';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  @Input() version: string;

  year: string = new Date().getFullYear().toString();

  public companyName = COMPANY_NAME;

  constructor() { }

}
